export enum AlertGoalType {
  DELTA_MOVING_AVG = 13,
  DELTA_MIN = 14,
  DELTA_MAX = 15,
  ABSOLUTE_VALUE = 16,
}
export enum AlertType {
  FUND = 1,
  QUERY = 2,
  STRATEGY = 3,
}
export enum AlertConditionType {
  EQUALS_TO = 1,
  LESS_THEN = 2,
  GREATER_THEN = 3,
  LESS_THEN_OR_EQUAL = 4,
  GREATER_THEN_OR_EQUAL = 5,
}

export interface AlertGoal {
  metric: AlertGoalType;
  condition: AlertConditionType;
  daysPeriod: number;
  referenceValue: number;
  goalValue: number;
  goalPercentageValue: number;
  previousValue: number;
}

export interface Alert {
  id: string;
  active: number;
  description: string;
  alertType: AlertType;
  createdAt: string;
  lastCalc: string;
  viewers: string[];
  notifyDelayDays: number;
  filters: {
    fundIds: string[];
  };
  goal: AlertGoal;
}
export interface AlertMultipleConditions {
  id: string;
  active: number;
  description: string;
  alertType: AlertType;
  createdAt: string;
  lastCalc: string;
  viewers: string[];
  notifyDelayDays: number;
  filters: {
    fundIds: string[];
  };
  goals: AlertGoal[];
}

export interface Notifications {
  count: number;
  notifications: Notification[];
}

export interface Notification {
  id: string;
  alertId: string;
  text: string;
  toLocalize: boolean;
  uid: string;
  readAt?: string;
  createdAt: string;
  dismissed: number;
  valuesArray: string[];
}
